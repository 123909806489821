import { Box } from '@chakra-ui/react';
import Container from '../../Layout/Container';
import Wrapper from '../Wrapper';
import { ParagraphTextFragmentFragment } from '../../../generated/types';
import { HTMLText } from '../../Common/HTMLText';
import React from 'react';

interface TextProps {
  data: ParagraphTextFragmentFragment;
  isInArticleLayout?: boolean;
}

const Text: React.FC<TextProps> = ({ data, isInArticleLayout }) => {
  const Inner = (
    <>
      {data.headline && <HTMLText text={data.headline} />}
      {data.body && (
        <Box marginTop={data.headline ? 2 : 0}>
          <HTMLText text={data.body} transformImages />
        </Box>
      )}
    </>
  );

  return (
    <Wrapper className="ParagraphText">
      {isInArticleLayout ? (
        Inner
      ) : (
        <Container className="ParagraphContainer">{Inner}</Container>
      )}
    </Wrapper>
  );
};

export default Text;
